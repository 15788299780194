// import { set_current_machine_regions_path } from 'js-routes/generated/routes';
import prepareDataByDepthLevels from '../helpers/prepareDataByDepthLevels';

/**
 * Defines the URL to apply the selected folders/groups
 *
 * @param {Set} checkedKeys
 * @param {Map} plainItemsIndex
 * @returns {Array}
 */
export default function defineApplyRegionIds(checkedKeys, plainItemsIndex) {
  const {
    depthLevels,
    nodesGroupedByDepth,
  } = prepareDataByDepthLevels(checkedKeys, plainItemsIndex);

  const machineRegionsIds = new Set();
  depthLevels.forEach((depth) => {
    nodesGroupedByDepth.get(depth).forEach((node) => {
      machineRegionsIds.add(node.get('id'));
    });
  });

  return [...machineRegionsIds];
}
