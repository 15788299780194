/* global document */
import React, { useState } from 'react';
import { Menu } from 'antd';
import { arrayOf, shape, string } from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

const SideMenu = ({ selectedKey, sideMenuItems }) => {
  const [openKeys, setOpenKeys] = useState([]);

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));

    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    // @ts-ignore
    <Scrollbars
      autoHide
      renderThumbVertical={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className="navigation-side-menu-container__scroll" {...props} />
      )}
    >
      <Menu
        className="navigation-side-menu"
        getPopupContainer={() => document.querySelector('.navigation-side-menu-container')}
        items={sideMenuItems}
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        theme="dark"
        mode="inline"
      />
    </Scrollbars>
  );
};

SideMenu.propTypes = {
  sideMenuItems: arrayOf(shape({})),
  selectedKey: string,
};

SideMenu.defaultProps = {
  sideMenuItems: [],
  selectedKey: null,
};

export default SideMenu;
