/* global window */

import React from 'react';

import {
  Button, Drawer, Result, Badge,
} from 'antd';
import { BellIcon } from 'components/cwo-icons';
import {
  arrayOf, bool, number, oneOf, shape, string,
} from 'prop-types';
import I18n from 'i18n-js';

import AlertsItem from './nested/AlertsItem';
import NotificationsList from './nested/NotificationsList';
import useTopMenuInstantNotifications from './hooks/useTopMenuInstantNotifications';

const TopMenuInstantNotifications = ({
  alertsNotifications,
  instantNotifications,
}) => {
  const {
    isDrawerOpen,
    notifications,
    containerHeight,
    containerRef,
    setNotifications,
    unreadNotificationsCount,
    handleOpenDrawer,
    handleCloseDrawer,
  } = useTopMenuInstantNotifications(instantNotifications);

  return (
    <div className="top-menu-instant-notifications">
      <Badge
        count={unreadNotificationsCount + alertsNotifications.count}
        offset={[-6, 4]}
      >
        <Button
          type="text"
          icon={<BellIcon />}
          onClick={handleOpenDrawer}
          className="navigation-account-management__bell-button"
        />
      </Badge>
      <Drawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        className="top-menu-instant-notifications__drawer"
        title={I18n.t('components.top_menu_instant_notifications.title')}
        zIndex={1032}
        closable
      >
        <AlertsItem alertsNotifications={alertsNotifications} />
        <div ref={containerRef} style={{ height: 'calc(100% - 43px)' }}>
          {window.consumer && notifications.length ? (
            <NotificationsList
              notifications={notifications}
              containerHeight={containerHeight}
              setNotifications={setNotifications}
              initialNextId={instantNotifications.initialNextId}
            />
          ) : (
            <Result
              status="404"
              title={I18n.t('components.top_menu_instant_notifications.no_notifications')}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

TopMenuInstantNotifications.propTypes = {
  instantNotifications: shape({
    initialNotifications: arrayOf(shape({
      id: number.isRequired,
      message: string.isRequired,
      description: string,
      labelType: oneOf(['info', 'success', 'warning', 'error']).isRequired,
      action: shape({
        url: string.isRequired,
        text: string,
      }),
      createdAt: string.isRequired,
      isRead: bool.isRequired,
    })),
    initialNextId: number,
  }),
  alertsNotifications: shape({
    path: string,
    count: number,
    disabled: bool,
  }).isRequired,
};

TopMenuInstantNotifications.defaultProps = {
  instantNotifications: {
    initialNotifications: [],
    initialNextId: null,
  },
};

export default TopMenuInstantNotifications;
