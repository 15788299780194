import React from 'react';
import { UnfilledStarIcon, FilledStarIcon } from 'components/cwo-icons';
import {
  arrayOf, func, number, shape, string,
} from 'prop-types';
import axios from 'axios';
import { Button, message } from 'antd';
import I18n from 'i18n-js';
import { set_favourite_path } from 'js-routes/generated/routes';

const FavouriteButton = ({
  item,
  itemsLimit,
  className,
  saveKey,
  favouriteItems,
  setFavouriteItems,
}) => {
  const isAlreadyFavourite = favouriteItems.some((fav) => fav.key === item.key);
  const updatedFavoriteList = isAlreadyFavourite
    ? favouriteItems.filter((fav) => fav.key !== item.key)
    : [...favouriteItems, item];

  const saveFavouriteToUserSession = async () => {
    try {
      /* eslint-disable camelcase */
      await axios({
        method: 'GET',
        url: set_favourite_path({
          favourite_key: saveKey,
          favourite_values: updatedFavoriteList.map(({ key }) => key),
        }),
      });
      /* eslint-enable camelcase */

      const toRemove = I18n.t('shared.favourite.messages.to_remove', { label: item.label });
      const toAdd = I18n.t('shared.favourite.messages.to_add', { label: item.label });

      message.success(isAlreadyFavourite ? toRemove : toAdd);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (favouriteItems.length === itemsLimit && !isAlreadyFavourite) {
      message.warning(I18n.t('shared.favourite.messages.limit', { limit: itemsLimit }));
      return;
    }

    setFavouriteItems(updatedFavoriteList);
    saveFavouriteToUserSession();
  };

  return (
    <Button
      className={`favourite-button ${className}`}
      onClick={handleClick}
      type="text"
    >
      {isAlreadyFavourite ? <FilledStarIcon /> : <UnfilledStarIcon />}
    </Button>
  );
};

FavouriteButton.propTypes = {
  className: string,
  setFavouriteItems: func,
  itemsLimit: number,
  saveKey: string.isRequired,
  item: shape({
    key: string,
    label: string,
    path: string,
  }).isRequired,
  favouriteItems: arrayOf(shape({
    key: string,
    label: string,
    path: string,
  })),
};

FavouriteButton.defaultProps = {
  className: '',
  setFavouriteItems: () => {},
  itemsLimit: 9999,
  favouriteItems: [],
};

export default FavouriteButton;
