/* global document */

// Global JS user session settings
//
// HOW-TO use:
// import { userId, areaUnits } from 'helpers/userSessionSettings';
//
// Global JS user session settings must be obtained only
// via this module.

// Extract data from DOM element.
// NOTE: ID have such complicated name to avoid collisions or
// manual access to this DOM element.
const configDOMElementName = 'global-app-js-config-9f22ba872c';
const configText = document.getElementById(configDOMElementName).textContent;

// NOTE: Rails javascript_tag adds CDATA tags.
// We need to clear CDATA tags to be able to parse JSON.
// https://github.com/rails/rails/blob/bd4e6d36c44352f7d762d1d967355e6899e72f4b/actionview/lib/action_view/helpers/javascript_helper.rb#L92
// https://github.com/rails/rails/blob/bd4e6d36c44352f7d762d1d967355e6899e72f4b/actionview/lib/action_view/helpers/tag_helper.rb#L347
const intextCDATARegexp = /\]\]><!\[CDATA\[/ig;
const startCDATARegexp = /^.*\/\/<!\[CDATA\[/is;
const endCDATARegexp = /\/\/\]\]>.*$/is;
const configClearedText =
  configText
    .replace(intextCDATARegexp, '')
    .replace(startCDATARegexp, '')
    .replace(endCDATARegexp, '');

const configJSON = JSON.parse(configClearedText);

const configData = Object.freeze({
  ...configJSON,
});

export const {
  environmentName,
  isDevelopment,
  isProduction,
  isTest,
  serverTime,
  currentTenant,
  currentLocale,
  currentTimeZoneName,
  companyId,
  companyTenant,
  companyCountry,
  companyName,
  userId,
  userName,
  userEmail,
  userPhone,
  userLocale,
  userManager,
  currentYear,
  timeZoneName,
  geoRasterImageVisibleServer,
  geoRasterImageNDVIServer,
  geoRasterImageNDVIContrastServer,
  ndviPalette,
  ndviPaletteId,
  rasterTileServerHost,
  rasterTileServer,
  vectorTileServer,
  UserTable,
  UnitsTable,
  ndviColorsToShowOnScales,
  downloadDisabled,
  BushelsEqTable,
  DefaultBushelEq,
  BushelsPerAcreTable,
  areaUnits,
  speedUnits,
  windSpeedUnits,
  lengthUnits,
  shortLengthUnits,
  precipitationUnits,
  temperatureUnits,
  productivityUnits,
  weightUnits,
  machineryWeightUnits,
  machineryDimensionsUnits,
  tankVolumeUnits,
  isTimecopFrozen,
  isOrchard,
  currentTimeUnixMilliseconds,
  areaRoundingPrecision,
  seedingRateUnits,
  seedingUnits,
  fuelConsumptionUnits,
  fuelConsumptionPerAreaUnits,
  fuelConsumptionPerTimeUnits,
  waterRateUnits,
  isMachinery,
  dontShowAgroTasksDropdownTour,
  dontShowNavigationMenuTour,
} = configData;
