import React from 'react';
import { Button } from 'antd';
import {
  bool, func, node, number, oneOfType, string,
} from 'prop-types';
import { ExpandMoreIcon } from 'components/cwo-icons';

const ToggleBtn = ({
  additionalContent,
  dispatcherTimerContent,
  showDrawer,
  loadMenuData,
  iconBackgroundColor,
  toggleKey,
  label,
  value,
  isVisible,
  icon,
  id,
}) => (
  isVisible && (
    <Button
      className="top-menu-field-groups__toggle-btn"
      onClick={() => showDrawer(toggleKey)}
      onMouseOver={loadMenuData}
      type="link"
      id={id}
    >
      {additionalContent}
      <span
        style={{ background: iconBackgroundColor }}
        className="top-menu-field-groups__btn-icon-container"
      >
        {icon}
      </span>
      <div className="top-menu-field-groups__btn-text-container">
        <span className="top-menu-field-groups__btn-text-container__label">{label}</span>
        <div className="top-menu-field-groups__btn-text-container__value">
          {dispatcherTimerContent}
          {value}
        </div>
      </div>
      <ExpandMoreIcon />
    </Button>
  )
);

ToggleBtn.propTypes = {
  showDrawer: func.isRequired,
  loadMenuData: func.isRequired,
  iconBackgroundColor: string.isRequired,
  toggleKey: number.isRequired,
  label: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  isVisible: bool,
  additionalContent: node,
  icon: node.isRequired,
  id: string.isRequired,
  dispatcherTimerContent: node,
};

ToggleBtn.defaultProps = {
  isVisible: true,
  additionalContent: null,
  dispatcherTimerContent: null,
};

export default ToggleBtn;
