import { useEffect, useState } from 'react';

const useDispatcherTimer = (showWorkRecord, initialSeconds) => {
  const [currentSeconds, setCurrentSeconds] = useState(null);

  useEffect(() => {
    if (!showWorkRecord || !initialSeconds) return null;

    setCurrentSeconds(initialSeconds);

    const interval = setInterval(() => {
      setCurrentSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSeconds]);

  const hours = String(Math.floor(currentSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((currentSeconds % 3600) / 60)).padStart(2, '0');
  const seconds = String(currentSeconds % 60).padStart(2, '0');

  return {
    dispatcherTimer: `${hours}:${minutes}:${seconds}`,
  };
};

export default useDispatcherTimer;
