import {
  string, shape, arrayOf, number, bool,
} from 'prop-types';

const sectionPropTypes = shape({
  key: string,
  label: string,
  items: arrayOf(shape({})),
});

export const triggerOptionsPropTypes = {
  selectedFavouriteItems: arrayOf(shape({
    key: string,
    label: string,
    path: string,
  })),
  allItems: arrayOf(shape({
    key: string,
    label: string,
    path: string,
  })),
};

export const sideMenuOptionsPropTypes = {
  monitoringCenter: shape({}),
  agro: shape({
    key: string,
    label: string,
    agro: sectionPropTypes,
    scouting: sectionPropTypes,
  }),
  telematics: shape({
    key: string,
    label: string,
    monitoring: sectionPropTypes,
    lists: sectionPropTypes,
  }),
  reports: shape({
    key: string,
    label: string,
    favourit: sectionPropTypes,
    agro: sectionPropTypes,
    telematics: sectionPropTypes,
  }),
  map: shape({
    key: string,
    label: string,
    fields: sectionPropTypes,
    landParcel: sectionPropTypes,
    other: sectionPropTypes,
  }),
  fields: shape({
    key: string,
    label: string,
    fields: sectionPropTypes,
  }),
  wms: shape({
    key: string,
    label: string,
    directories: sectionPropTypes,
    documents: sectionPropTypes,
    reports: sectionPropTypes,
  }),
  more: shape({
    key: string,
    label: string,
    items: arrayOf(shape({
      key: string,
      label: string,
      path: string,
    })),
  }),
  platform: shape({
    key: string,
    label: string,
    path: string,
  }),
};

export const topMenuOptionsPropTypes = {
  account: shape({
    account: sectionPropTypes,
    company: sectionPropTypes,
    key: string,
    logoSrc: string,
  }),
  alertsNotification: shape({
    path: string,
    count: number,
    show: bool,
  }),
  question: shape({
    key: string,
    contactUs: sectionPropTypes,
    support: sectionPropTypes,
  }),
  settings: shape({
    key: string,
    catalogs: sectionPropTypes,
    company: sectionPropTypes,
    telematics: sectionPropTypes,
    userManagement: sectionPropTypes,
  }),
};
