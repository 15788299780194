import React from 'react';
import { Dropdown } from 'antd';
import { PlusIcon } from 'components/cwo-icons';
import I18n from 'i18n-js';
import { func, shape } from 'prop-types';
import { triggerOptionsPropTypes } from '../propTypes';
import useTrigger from '../hooks/useTrigger';

const Trigger = ({
  triggerOptions,
  setDropdownOpen,
  triggerButtonRef,
}) => {
  const { items, showTrigger } = useTrigger(triggerOptions);

  return showTrigger && (
    <Dropdown
      onOpenChange={(visible) => setDropdownOpen(visible)}
      overlayClassName="trigger-dropdown-container"
      menu={{
        items,
        className: 'trigger-dropdown',
        triggerSubMenuAction: 'click',
      }}
      trigger={['click']}
    >
      <button
        ref={triggerButtonRef}
        type="button"
        className="trigger-btn btn btn-success"
      >
        <PlusIcon />
        <span className="trigger-btn__label">
          {I18n.t('shared.side_menu.trigger.create')}
        </span>
      </button>
    </Dropdown>
  );
};

Trigger.propTypes = {
  triggerOptions: shape(triggerOptionsPropTypes),
  setDropdownOpen: func,
  triggerButtonRef: shape({}),
};

Trigger.defaultProps = {
  triggerOptions: {
    selectedFavouriteItems: [],
    allItems: [],
  },
  setDropdownOpen: () => {},
  triggerButtonRef: null,
};

export default Trigger;
