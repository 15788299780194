import React, { useState, useEffect } from 'react';
import { Layout, Tour } from 'antd';
import { shape, string } from 'prop-types';
import { dontShowNavigationMenuTour } from 'helpers/userSessionSettings';
import { sideMenuOptionsPropTypes, topMenuOptionsPropTypes } from './propTypes';
import RightPart from './nested/RightPart';
import LeftPart from './nested/LeftPart';
import SideMenu from './nested/SideMenu';
import Trigger from './nested/Trigger';
import useSideMenuItems from './hooks/useSideMenuItems';
import useTour from './hooks/useTour';

const { Header } = Layout;

const NavigationMenu = ({
  currentOpenSection,
  sideMenuOptions,
  topMenuOptions,
  currentLocale,
  selectedKey,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filterCollapseActiveKey, setFilterCollapseActiveKey] = useState(1);

  const shouldCollapse = (!isHovered && dropdownOpen) ? (!isHovered && !dropdownOpen) : collapsed;

  const sideMenuItems = useSideMenuItems(
    sideMenuOptions,
    collapsed,
    isHovered,
    selectedKey,
    currentOpenSection,
  );

  const {
    steps,
    currentStep,
    openTour,
    sideMenuRef,
    triggerButtonRef,
    sideMenuButtonRef,
    filterButtonsRef,
    dispatcherBlockRef,
    questionButtonRef,
    handleTourClose,
  } = useTour(
    setCollapsed,
    topMenuOptions,
    sideMenuItems,
    setFilterDrawerOpen,
    setFilterCollapseActiveKey,
  );

  /* eslint-disable no-undef */
  const [collapsedWidth, setCollapsedWidth] = useState(() => (
    document.documentElement.clientWidth < 600 ? 0 : 64
  ));

  useEffect(() => {
    const handleResize = () => {
      setCollapsedWidth(document.documentElement.clientWidth < 600 ? 0 : 64);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  /* eslint-enable no-undef */

  return (
    <>
      <Header className="navigation-top-menu">
        <LeftPart
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sideMenuButtonRef={sideMenuButtonRef}
          currentCountry={topMenuOptions.currentCountry}
        />
        <RightPart
          topMenuOptions={topMenuOptions}
          currentLocale={currentLocale}
          filterButtonsRef={filterButtonsRef}
          dispatcherBlockRef={dispatcherBlockRef}
          questionButtonRef={questionButtonRef}
          filterDrawerOpen={filterDrawerOpen}
          setFilterDrawerOpen={setFilterDrawerOpen}
          filterCollapseActiveKey={filterCollapseActiveKey}
          setFilterCollapseActiveKey={setFilterCollapseActiveKey}
        />
      </Header>

      <Layout.Sider
        ref={sideMenuRef}
        className="navigation-side-menu-container"
        collapsible
        collapsed={!isHovered && shouldCollapse}
        width={280}
        collapsedWidth={collapsedWidth}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        trigger={null}
      >
        <SideMenu
          selectedKey={selectedKey}
          sideMenuItems={sideMenuItems}
        />
        <Trigger
          triggerOptions={sideMenuOptions.trigger}
          setDropdownOpen={setDropdownOpen}
          triggerButtonRef={triggerButtonRef}
        />
      </Layout.Sider>
      {!dontShowNavigationMenuTour && (
        <Tour
          open={openTour}
          steps={steps}
          zIndex={1034}
          current={currentStep}
          onClose={handleTourClose}
          gap={{ offset: 0 }}
        />
      )}
    </>
  );
};

NavigationMenu.propTypes = {
  sideMenuOptions: shape(sideMenuOptionsPropTypes),
  topMenuOptions: shape(topMenuOptionsPropTypes),
  selectedKey: string,
  currentOpenSection: string,
  currentLocale: string.isRequired,
};

NavigationMenu.defaultProps = {
  currentOpenSection: null,
  selectedKey: null,
  sideMenuOptions: {
    monitoringCenter: {},
    agro: {},
    telematics: {},
    reports: {},
    map: {},
    fields: {},
    wms: {},
    more: {},
    platform: {},
  },
  topMenuOptions: {
    account: {},
    filter: {},
    notification: {},
    question: {},
    settings: {},
  },
};

export default NavigationMenu;
