import React from 'react';
import {
  bool, number, shape, string,
} from 'prop-types';
import { Badge, Button, Divider } from 'antd';
import { AlertsIcon, ArrowRightIcon } from 'components/cwo-icons';
import I18n from 'i18n-js';

const AlertsItem = ({ alertsNotifications }) => (
  <>
    <Button
      disabled={alertsNotifications.disabled}
      className="top-menu-instant-notifications__alerts-link"
      href={alertsNotifications.path}
      type="link"
      icon={<AlertsIcon />}
    >
      <span className="top-menu-instant-notifications__alerts-link-title">
        {I18n.t('alerts.index.alerts')}
      </span>
      <Badge
        count={alertsNotifications.count}
        offset={[-30, 9]}
      >
        <ArrowRightIcon />
      </Badge>
    </Button>
    <Divider style={{ margin: '0px' }} />
  </>
);

AlertsItem.propTypes = {
  alertsNotifications: shape({
    path: string,
    count: number,
    disabled: bool,
  }).isRequired,
};

export default AlertsItem;
