/* eslint-disable react/jsx-props-no-spreading */

// TODO: Deprecate. Remove this file after realized new AWP design

import React, { lazy, Suspense } from 'react';
import CWOConfigProvider from 'helpers/cwoConfigProvider';
import Loading from 'helpers/loading/loading';

const Component = lazy(() => import('components/agri-work-plan/form'));

const AgriWorkPlanForm = (props) => (
  <CWOConfigProvider>
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  </CWOConfigProvider>
);

export default AgriWorkPlanForm;
