/* global document */
import { useEffect, useRef, useState } from 'react';
import { dontShowNavigationMenuTour } from 'helpers/userSessionSettings';
import { dont_show_again_path } from 'js-routes/generated/routes';
import { has } from 'lodash-es';
import I18n from 'i18n-js';
import axios from 'axios';

const saveDontShowAgainToUserSession = async () => {
  /* eslint-disable camelcase */
  await axios({
    method: 'GET',
    url: dont_show_again_path({
      dont_show_subject: 'dont_show_navigation_menu_tour',
      dont_show_value: true,
    }),
  });
  /* eslint-enable camelcase */
};

const prevStep = (setCurrentStep, currentStep) => {
  setTimeout(() => {
    setCurrentStep(currentStep - 1);
  }, 200);
};

const nextStep = (setCurrentStep, currentStep) => {
  setTimeout(() => {
    setCurrentStep(currentStep + 1);
  }, 200);
};

const useTour = (
  setCollapsed,
  topMenuOptions,
  sideMenuItems,
  setFilterDrawerOpen,
  setFilterCollapseActiveKey,
) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [openTour, setOpenTour] = useState(!dontShowNavigationMenuTour);

  const sideMenuRef = useRef(null);
  const reportsRef = useRef(null);
  const triggerButtonRef = useRef(null);
  const sideMenuButtonRef = useRef(null);
  const filterButtonsRef = useRef(null);
  const dispatcherBlockRef = useRef(null);
  const questionButtonRef = useRef(null);

  useEffect(() => {
    if (openTour) {
      reportsRef.current = document.querySelector('#side-menu-reports-section');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuItems.length]);

  if (dontShowNavigationMenuTour) {
    return {};
  }

  const showDispatcherStep = topMenuOptions
    .filter
    .settings
    .showDispatcherWorkRecord;

  const handleTourClose = () => {
    setOpenTour(false);
    saveDontShowAgainToUserSession();
  };

  const steps = [
    {
      title: I18n.t('shared.tour.side_menu.title'),
      description: I18n.t('shared.tour.side_menu.description'),
      target: () => sideMenuRef.current,
      nextButtonProps: {
        onClick: () => {
          setCollapsed(false);
          nextStep(setCurrentStep, currentStep);
        },
      },
      placement: 'left',
    },
    {
      title: I18n.t('shared.tour.reports_section.title'),
      description: I18n.t('shared.tour.reports_section.description'),
      target: () => reportsRef.current,
      prevButtonProps: {
        onClick: () => {
          setCollapsed(true);
          prevStep(setCurrentStep, currentStep);
        },
      },
      nextButtonProps: { onClick: () => nextStep(setCurrentStep, currentStep) },
      placement: 'bottom',
    },
    {
      title: I18n.t('shared.tour.trigger_button.title'),
      description: I18n.t('shared.tour.trigger_button.description'),
      target: () => triggerButtonRef.current,
      prevButtonProps: { onClick: () => prevStep(setCurrentStep, currentStep) },
      nextButtonProps: {
        onClick: () => {
          setCollapsed(true);
          nextStep(setCurrentStep, currentStep);
        },
      },
      placement: 'top',
    },
    {
      title: I18n.t('shared.tour.side_menu_button.title'),
      description: I18n.t('shared.tour.side_menu_button.description'),
      target: () => sideMenuButtonRef.current,
      prevButtonProps: {
        onClick: () => {
          setCollapsed(false);
          prevStep(setCurrentStep, currentStep);
        },
      },
      nextButtonProps: { onClick: () => nextStep(setCurrentStep, currentStep) },
      placement: 'bottom',
    },
    {
      title: I18n.t('shared.tour.filter_buttons.title'),
      description: I18n.t('shared.tour.filter_buttons.description'),
      target: () => filterButtonsRef.current,
      prevButtonProps: { onClick: () => prevStep(setCurrentStep, currentStep) },
      nextButtonProps: {
        onClick: () => {
          nextStep(setCurrentStep, currentStep);

          if (showDispatcherStep) {
            setFilterDrawerOpen(true);
            setFilterCollapseActiveKey(3);
          }
        },
      },
      placement: 'bottom',
    },
    {
      showStep: showDispatcherStep,
      title: I18n.t('shared.tour.dispatcher_block.title'),
      description: I18n.t('shared.tour.dispatcher_block.description'),
      target: () => dispatcherBlockRef.current,
      prevButtonProps: {
        onClick: () => {
          prevStep(setCurrentStep, currentStep);
          setFilterDrawerOpen(false);
          setFilterCollapseActiveKey(1);
        },
      },
      nextButtonProps: {
        onClick: () => {
          nextStep(setCurrentStep, currentStep);
          setFilterDrawerOpen(false);
          setFilterCollapseActiveKey(1);
        },
      },
      placement: 'bottom',
    },
    {
      title: I18n.t('shared.tour.question_button.title'),
      description: I18n.t('shared.tour.question_button.description'),
      target: () => questionButtonRef.current,
      prevButtonProps: {
        onClick: () => {
          prevStep(setCurrentStep, currentStep);

          if (showDispatcherStep) {
            setFilterDrawerOpen(true);
            setFilterCollapseActiveKey(3);
          }
        },
      },
      nextButtonProps: { onClick: () => nextStep(setCurrentStep, currentStep) },
      placement: 'bottom',
    },
  ];

  const filteredSteps = steps.filter((step) => !has(step, 'showStep') || step.showStep);

  return {
    steps: filteredSteps,
    currentStep,
    openTour,
    setOpenTour,
    sideMenuRef,
    triggerButtonRef,
    sideMenuButtonRef,
    filterButtonsRef,
    dispatcherBlockRef,
    questionButtonRef,
    handleTourClose,
  };
};

export default useTour;
