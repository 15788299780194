/* global window, document */
// @ts-nocheck
import React from 'react';
import I18n from 'i18n-js';
import { userLocale } from 'helpers/userSessionSettings';
import { Typography } from 'antd';

const ZendeskWidget = () => {
  const scriptExists = () => !!document.querySelector('#ze-snippet');

  const activateWidget = () => {
    window.zE.activate(); // legacy because 'open' does not work properly when widget is hidden;
  };

  const loadStaticScript = () => {
    const snippetScript = document.createElement('script');
    snippetScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=c91edc09-f80a-48eb-b74e-87f02d1aa26b';
    snippetScript.type = 'text/javascript';
    snippetScript.id = 'ze-snippet';
    document.body.prepend(snippetScript);
  };

  const loadCustomSettings = () => {
    const staticScript = document.querySelector('#ze-snippet');
    staticScript.addEventListener('load', () => {
      // Must be on top of the script due to https://developer.zendesk.com/embeddables/docs/widget/core#setlocale
      window.zE('webWidget', 'setLocale', userLocale); // set widget locale based on Cropwise selected locale
      window.zE('webWidget', 'open'); // opens widget when it appears on page
      window.zE('webWidget:on', 'close', () => { // hides widget button when user minimizes widget
        window.zE('webWidget', 'hide');
      });
    });
    window.zESettings = {
      webWidget: {
        color: {
          theme: '#73DC78',
          button: '#73DC78',
          header: '#73DC78',
        },
      },
    };
  };

  const loadChatWidget = () => {
    loadStaticScript();
    loadCustomSettings();
  };

  const handleWindowOpen = (event) => {
    event.preventDefault();

    if (scriptExists()) {
      activateWidget();
    } else {
      loadChatWidget();
    }
  };

  return (
    <Typography.Link
      href="#zendesk-chat-widget-link"
      onClick={handleWindowOpen}
      className="zendesk-chat-widget-link"
    >
      {I18n.t('shared.top_menu.online_support')}
    </Typography.Link>
  );
};

export default ZendeskWidget;
