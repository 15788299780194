/* eslint-disable no-undef */
import React from 'react';
import { Flex, Typography } from 'antd';
import { CopyIcon } from 'components/cwo-icons';
import ZendeskWidget from 'components/zendesk-widget';
import { shape, string } from 'prop-types';

const { Link, Paragraph } = Typography;
const COPYABLE_KEYS = ['email', 'phone'];

const MenuLink = ({ item, currentLocale }) => {
  const { key, label, path } = item;

  const copyableProps = {
    text: label,
    icon: <CopyIcon />,
    tooltips: false,
  };

  const handleSignOut = async (event) => {
    if (key === 'account-sign-out') {
      event.preventDefault();

      try {
        await fetch(path, { method: 'DELETE' });
        window.location.href = window.location.origin;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const defaultLink = (content) => (
    <Link
      href={path}
      className="navigation-top-menu__link"
      onClick={handleSignOut}
    >
      <Paragraph copyable={COPYABLE_KEYS.includes(key) ? copyableProps : false}>
        {content}
      </Paragraph>
    </Link>
  );

  const languageLabel = () => {
    const { children } = item;
    const findCurrentLanguage = children.find((child) => child.key === currentLocale);

    return (
      <>
        <span>{label}</span>
        <span style={{ fontWeight: '400', color: '#696f88' }}>
          {findCurrentLanguage.label}
        </span>
      </>
    );
  };

  const userLabel = () => {
    const style = { fontWeight: '400', fontSize: '12px', color: '#696f88' };
    const { email } = item;

    return (
      <Flex vertical align="start">
        <span>{label}</span>
        <span style={style}>
          {email}
        </span>
      </Flex>
    );
  };

  switch (key) {
    case 'zendesk-widget':
      return <ZendeskWidget />;
    case 'account-language':
      return defaultLink(languageLabel());
    case 'account-user':
      return defaultLink(userLabel());
    default:
      return defaultLink(label);
  }
};

MenuLink.propTypes = {
  item: shape({}).isRequired,
  currentLocale: string.isRequired,
};

export default MenuLink;
