import React from 'react';
import {
  bool, number, shape, string,
} from 'prop-types';
import useDispatcherTimer from '../hooks/useDispatcherTimer';

const DispatcherTimer = ({ dispatcherWorkRecord }) => {
  const { showWorkRecord, initialSeconds } = dispatcherWorkRecord;
  const { dispatcherTimer } = useDispatcherTimer(showWorkRecord, initialSeconds);

  return <small>{dispatcherTimer}</small>;
};

DispatcherTimer.propTypes = {
  dispatcherWorkRecord: shape({
    showWorkRecord: bool,
    initialSeconds: number,
    durationApproachingMessage: string,
  }),
};

DispatcherTimer.defaultProps = {
  dispatcherWorkRecord: {
    showWorkRecord: false,
    initialSeconds: null,
    durationApproachingMessage: null,
  },
};

export default DispatcherTimer;
