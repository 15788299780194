/* global window */

import { useEffect, useState } from 'react';
import { notification } from 'antd';

import useActionCable from 'hooks/useActionCable';
import useResizeObserver from 'hooks/useResizeObserver';

import axios from 'axios';
import { mark_all_as_read_instant_notifications_path } from 'js-routes/generated/routes';
import { performAction } from 'components/top-menu-instant-notifications/helpers';
import NotificationsListItemContent from '../nested/NotificationsListItemContent';

const useTopMenuInstantNotifications = ({
  initialNotifications,
  initialNextId,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [containerHeight, setContainerHeight] = useState(300);
  const [containerRef, containerOptions] = useResizeObserver();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(null);
  const [notifications, setNotifications] = useState([
    ...initialNotifications,
    ...(initialNextId ? [{ id: 'load-more', isRead: true }] : []),
  ]);

  useEffect(() => {
    const { contentRect } = containerOptions;
    setContainerHeight((prevHeight) => (contentRect?.height || prevHeight));
  }, [containerOptions]);

  useEffect(() => {
    setUnreadNotificationsCount(notifications.filter(({ isRead }) => !isRead).length);
  }, [notifications]);

  const markAsReadAll = () => {
    axios({
      url: mark_all_as_read_instant_notifications_path(),
      method: 'POST',
    }).then(() => {
      setNotifications((prevState) => prevState.map((n) => ({ ...n, isRead: true })));
    });
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    markAsReadAll();
  };

  const handleReceiveNotification = (data) => {
    const {
      id,
      message,
      description,
      labelType,
      action,
    } = data;

    setNotifications((prevNotifications) => [
      data,
      ...prevNotifications,
    ]);

    const handlePerformAction = () => {
      performAction(id).then(({ data: { redirectTo } }) => {
        window.location.href = redirectTo;
      });
    };

    notification[labelType]({
      placement: 'bottomRight',
      message,
      description: NotificationsListItemContent({ description, action, handlePerformAction }),
    });
  };

  useActionCable('PersonalNotificationsChannel', { onReceive: handleReceiveNotification });

  return {
    isDrawerOpen,
    notifications,
    containerHeight,
    containerRef,
    setNotifications,
    unreadNotificationsCount,
    handleOpenDrawer,
    handleCloseDrawer,
  };
};

export default useTopMenuInstantNotifications;
