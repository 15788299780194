import React from 'react';
import { monitoring_center_path, orchards_monitoring_center_path } from 'js-routes/generated/routes';
import {
  bool, func, shape, string,
} from 'prop-types';
import { Button, Typography, Space } from 'antd';
import { isOrchard } from 'helpers/userSessionSettings';
import {
  CwoLogo,
  CwoLogoRu,
  OpenMenuIcon,
  CloseMenuIcon,
} from 'components/cwo-icons';

const { Link } = Typography;

const logoUrl = isOrchard ? orchards_monitoring_center_path() : monitoring_center_path();
const logoForCountry = { ru: <CwoLogoRu />, default: <CwoLogo /> };

const LeftPart = ({
  collapsed,
  setCollapsed,
  currentCountry,
  sideMenuButtonRef,
}) => (
  <Space className="navigation-top-menu-left-part">
    <Button
      ref={sideMenuButtonRef}
      className="navigation-top-menu-left-part__sider-button"
      type="link"
      icon={collapsed ? <OpenMenuIcon /> : <CloseMenuIcon />}
      onClick={() => setCollapsed(!collapsed)}
    />
    <Link className="navigation-top-menu-left-part__logo" href={logoUrl}>
      {logoForCountry[currentCountry] || logoForCountry.default}
    </Link>
  </Space>
);

LeftPart.propTypes = {
  collapsed: bool,
  setCollapsed: func.isRequired,
  sideMenuButtonRef: shape({}),
  currentCountry: string.isRequired,
};

LeftPart.defaultProps = {
  collapsed: true,
  sideMenuButtonRef: null,
};

export default LeftPart;
