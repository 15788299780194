const excludedKeys = ['label', 'key', 'logoSrc', 'path', 'favourite', 'initials'];
export const findGroupKeys = (section) => (
  Object
    .keys(section)
    .filter((key) => !excludedKeys.includes(key))
);

export const divider = { type: 'divider' };

export const buildSection = (section, builder) => {
  const groupKeys = findGroupKeys(section);

  return groupKeys.flatMap((groupKey, index) => {
    const showDivider = index !== groupKeys.length - 1;

    if (showDivider) {
      return [builder(section, groupKey), divider];
    }

    return [builder(section, groupKey)];
  });
};
