/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import CWOConfigProvider from 'helpers/cwoConfigProvider';
import Component from 'components/navigation-menu';

const NavigationMenu = (props) => (
  /*
    NOTE: Special case !!!
    Top menu is always visible, so don't need lazy load.
  */

  <CWOConfigProvider>
    <Component {...props} />
  </CWOConfigProvider>
);

export default NavigationMenu;
